/* eslint-disable import/prefer-default-export, react/prop-types */

import React from "react";
import TopLayout from "./src/components/TopLayout";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "@fontsource/domine";
import "@fontsource/lato";

import "./src/styles/global.css";
import "./src/style.css";

// Highlighting for code blocks
import "prismjs/themes/prism.css";
import { MDXEmbedProvider } from "mdx-embed";

export const wrapRootElement = ({ element }) => {
  return (
    <TopLayout>
      <MDXEmbedProvider>{element}</MDXEmbedProvider>
    </TopLayout>
  );
};
